<template>
  <v-dialog v-model="modalSancion" persistent max-width="1000">
    <v-card class="pa-4">
      <v-row>
        <!-- corner button -->
        <v-col cols="1">
          <v-btn
            :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
            @click="cerrarModal"
            icon
            x-large
          >
            <v-icon v-text="'mdi-close'" color="secondary" />
          </v-btn>
        </v-col>

        <!-- content -->
        <v-col cols="11" class="mt-4">
          <v-row class="pt-1">
            <v-col cols="12" md="6" class="d-flex align-center">
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column  ml-2">
            <div class="d-flex flex-column mb-2">
              <span class="text-caption" v-text="'Nombre de proveedor'" />
              <span v-text="detalleSancionProp?.[0]?.nombre || detalleSancionProp?.[0]?.[0]?.nombre" />
            </div>

            <template v-if="detalleSancionProp && detalleSancionProp?.[0]?.[0]?.nombre">
              <div v-for="(registro, index) in detalleSancionProp[0]" :key="index">
                <div v-if="typeof registro === 'object'">
                  <template>
                    <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Sanción aplicada'" />
                      <span v-text="registro.detalle_sancion" />
                    </div>
                  
                    <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Fecha de inicio'" />
                      <span v-text="registro.fecha_inicio" />
                    </div>
                  
                    <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Fecha de finalización'" />
                      <span v-text="registro.fecha_fin" />
                    </div>

                    <div class="d-flex flex-column my-2">
                      <span class="text-caption" v-text="'Institución que sanciona'" />
                      <span v-text="registro.nombre_institucion" />
                    </div>
                  </template>

                  <v-btn
                    v-if="haveRole('ROLE_ELIMINAR_SANCIONES_LISTA_HOMOLOGADA_VIEW')"
                    @click="abrirModalEliminar(item = registro.id_sancion)"
                    color="error"
                    class="mb-8" 
                    >
                    Eliminar
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            
            <template v-else-if="detalleSancionProp?.[0]?.nombre">
              <div>
                <div v-for="(sancion, index) in detalleSancionProp" :key="index">
                  <div class="d-flex flex-column my-2">
                    <span class="text-caption" v-text="'Sanción aplicada'" />
                    <span v-text="sancion.detalle_sancion" />
                  </div>

                  <div class="d-flex flex-column my-2">
                    <span class="text-caption" v-text="'Fecha de inicio'" />
                    <span v-text="sancion.fecha_inicio" />
                  </div>

                  <div class="d-flex flex-column my-2">
                    <span class="text-caption" v-text="'Fecha de finalización'" />
                    <span v-text="sancion.fecha_fin" />
                  </div>

                  <div class="d-flex flex-column my-2">
                    <span class="text-caption" v-text="'Institución que sanciona'" />
                    <span v-text="sancion.nombre_institucion" />
                  </div>

                  <v-btn
                    v-if="haveRole('ROLE_ELIMINAR_SANCIONES_LISTA_HOMOLOGADA_VIEW')"
                    @click="abrirModalEliminar(item = sancion.id_sancion)"
                    color="error"
                    class="mb-9"
                    >
                    Eliminar
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </template>

          </v-row>

          <!-- button to close -->
          <v-row class="mb-3 mt-12">
            <v-btn
              @click="cerrarModal"
              class="mx-3" 
              color="secondary" 
              outlined 
              v-text="'volver'"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card>


    <v-dialog v-model="showDeleteUserDialog" max-width="800" persistent>
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-center primary--text">
          <p class="mt-5">{{ `¿Desea eliminar la sanción asociada al proveedor ${detalleSancionProp?.[0]?.nombre || detalleSancionProp?.[0]?.[0]?.nombre}?` }}</p>
        </v-card-title>
        <v-card-text class="d-flex justify-center secondary--text">
          <p>De confirmarse la siguiente acción, debe cargar una resolución.</p>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-file-input
            v-model="adjunto"
            label="Adjunto"
            placeholder="Seleccione un PDF"
            outlined
            light
            accept="application/pdf"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-center secondary--text">
          <v-btn
            color="primary"
            class="mb-5"
            outlined
            @click="cerrarModalEliminar()"
          >
            Cancelar
          </v-btn>
          <v-btn
          :disabled="!adjunto"
            color="primary"
            class="mb-5"
            @click="deleteRegistro(idSancion)"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-dialog>
  

</template>

<script>
// importacion de librerias
import ConfirmationDialogComponent from "../../../components/ConfirmationDialogComponent.vue";


export default {
  components: {
    ConfirmationDialogComponent,
  },

  name: "InformacionSancionComponent",
  props: {
    modalSancion:{
      type: Boolean,
      required: true,
    },
    detalleSancionProp: {
      type: [Array, Object],
      default: () => []
    },

  },
  data() {
    return {
      showDeleteUserDialog: false,
      adjunto: null,
      idSancion: null,
    }
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrarModal");
      this.adjunto= null;
    },
    cerrarModalEliminar() {
      this.showDeleteUserDialog = false;
      this.adjunto = null;
    },
    abrirModalEliminar(item){
      this.idSancion = item;
      this.showDeleteUserDialog = true
    },
    async deleteRegistro() {
      const formData = new FormData();
      formData.append('adjunto', this.adjunto);
      
      const fuente = this.detalleSancionProp[0].fuente;
      const response = await this.services.Sanciones.deleteListaSanciones(this.idSancion, fuente, formData);
      this.showDeleteUserDialog = false;

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });
      }
      this.cerrarModal();
      this.$emit('recargarLista');
    },
  },

};
</script>

<style scoped>
.closeBtn {
  margin: 20px 30px;
}

/*  media query para modificar margin de button corner */ 
@media (max-width: 1700px) {
  .closeBtn {
    margin: 20px 20px;
  }
}
</style>
